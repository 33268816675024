<template>
  <responsive-form :form="form">
    <a-form-item label="ID" v-if="!!this.model">
      <a-input v-decorator="['id', { initialValue: 0 }]" :disabled="this.model != null" />
    </a-form-item>
    <a-form-item label="KeyChip ID">
      <a-input v-decorator="['serial', {rules: [{required: true}]}]" />
    </a-form-item>
    <a-form-item label="活动模板ID">
      <a-input v-decorator="['templateId', {rules: [{required: true}], initialValue: '1'}]" />
    </a-form-item>
  </responsive-form>
</template>

<script>
import pick from 'lodash.pick'
import ResponsiveForm from '@/components/ResponsiveForm/ResponsiveForm'

// 表单字段
const fields = ['id', 'serial', 'templateId']

export default {
  components: { ResponsiveForm },
  props: {
    model: {
      type: Object,
      default: () => null
    },
    isTemplate: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      form: this.$form.createForm(this)
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
